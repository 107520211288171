body {
    font-family: 'Roboto', Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #121212;
    color: #e0e0e0;
  }
  
  .board-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .board-content {
    padding-top: 0;
  }
  
  .board-wrapper {
    padding: 0 20px;
    margin-top: 0;
  }
  
  .board-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 80px;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
  }
  
  .board-title {
    display: flex;
    align-items: center;
    margin: 0;
    color: #888;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .board-info-icon {
    font-size: 0.7rem;
    color: grey;
    cursor: pointer;
    margin-left: 8px;
  }
  
  .board-ttl {
    color: #888;
    font-size: 0.7rem;
    margin-top: 5px;
  }
  
  .columns-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .column {
    flex: 1;
    padding: 0 15px;
  }
  
  .column:first-child {
    padding-left: 0;
  }
  
  .column:last-child {
    padding-right: 0;
  }
  
  .column-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .column-header h2 {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    color: #888;
  }
  
  .column-header .info-icon {
    font-size: 1rem;
    color: grey;
    cursor: pointer;
    margin-left: 8px;
  }
  
  .add-card-button {
    color: #888 !important;
  }
  
  .add-card-button:hover {
    color: #bb86fc !important;
  }
  
  .cards-container {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
  
  .error-message {
    color: #888;
    text-align: center;
    padding: 20px;
    font-size: 0.8rem;
  }
  
  .loading {
    text-align: center;
    padding: 40px;
    font-size: 1.5rem;
    color: #bb86fc;
  }
  
  .cards-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .cards-container::-webkit-scrollbar-track {
    background: #1e1e1e;
  }
  
  .cards-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .cards-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .card {
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card p {
    margin: 0 0 10px 0;
    font-size: 1rem;
    line-height: 1.4;
  }
  
  .card-green {
    background-color: #1e3a2e;
    color: #a7e3c5;
  }
  
  .card-red {
    background-color: #3a1e1e;
    color: #e3a7a7;
  }
  
  .card-purple {
    background-color: #2e1e3a;
    color: #c5a7e3;
  }
  
  .upvotes {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.9rem;
  }
  
  .upvote-icon {
    cursor: pointer;
    margin-right: 4px;
    transition: transform 0.2s ease-in-out;
  }
  
  .upvote-icon:hover {
    transform: translateY(-2px);
  }
  
  .upvote-icon.voted {
    font-weight: bold;
  }
  
  .card-green .upvote-icon {
    color: #7fcfa1;
  }
  
  .card-red .upvote-icon {
    color: #cf7f7f;
  }
  
  .card-purple .upvote-icon {
    color: #a17fcf;
  }
  
  .upvote-icon.voted {
    color: #bb86fc;
  }
  
  .empty-board-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    text-align: center;
  }
  
  .empty-board-message p {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 20px;
  }
  
  .create-card-button {
    background-color: #bb86fc !important;
    color: #121212 !important;
  }
  
  .create-card-button:hover {
    background-color: #9162c9 !important;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
  }
  
  .loading-indicator {
    color: #bb86fc !important;
  }


  
  @media (max-width: 768px) {
    .columns-container {
      flex-direction: column;
    }
  
    .column {
      padding: 0;
      margin-bottom: 20px;
    }
  }