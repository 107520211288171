body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #121212;
  color: #e0e0e0;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.centered-container {
  text-align: center;
}

.create-board-button {
  background-color: #1e1e1e;
  color: #bb86fc;
  border: 2px solid #bb86fc;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.create-board-button:hover {
  background-color: #bb86fc;
  color: #1e1e1e;
}

.plus-icon {
  font-size: 24px;
  margin-right: 10px;
}
