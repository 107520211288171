.header {
    width: 100%;
    background-color: #1e1e1e;
    padding: 15px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .header-content {
    display: flex;
    justify-content: flex-start; /* Align left */
    align-items: center;
  }
  
  .header-title {
    font-size: 24px;
    color: #bb86fc;
    margin-left: 10px; /* Space between the icon and text */
  }
  